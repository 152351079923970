.App {
    min-height: 100vh;
    height: 1px; /*hack*/
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
}
.body-content > main {
    overflow: auto;
}
.img {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 50%;
}
main {
    min-height: calc(100% - 80pt);
}
.deleted-post {
    position: absolute;
    right: 10px;
    top: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background-color: #fff;
    cursor: pointer;
}
img.img {
    width: 60px !important;
    border-radius: 50% !important;
    height: 60px !important;
}

.navLink.imageDropdownButton {
    padding: 0 !important;
    margin: 0 !important;
}

.listItem {
    padding: 0 !important;
}

.list {
    padding: 0 !important;
}
.App > header {
    margin-bottom: 0px !important;
    border-radius: 0px !important;
    background: linear-gradient(91deg, #fe9b35, #ff7636) !important;
}

@media (min-width: 1200px) {
    .App > header > div {
        max-width: calc(100% - 100px) !important;
    }
}

.body-content {
    display: flex;
    flex: 1;
    background-color: rgb(250, 250, 250);
}

.sidebar {
    order: -1;
    flex: 0 0 200px;
    background-color: #e8eaf6;
    padding: 20px 0px 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.sidebar > ul {
    padding: 0px 0px 0px 00px;
    margin: 0px;
    list-style: none;
}

.sidebar > ul > li {
    margin-top: 0px;
}
.sidebar > ul > li > a > button {
    width: 100%;
    margin: 0;
    border-radius: 0px;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
}

.sidebar > ul > li > a > button > span {
    justify-content: left;
}

/* .sidebar > ul > li > a > button:focus */

.sidebar > ul > li > a > button:hover {
    background-color: rgb(255, 255, 255) !important;
    color: rgba(0, 0, 0, 0.87) !important;
}
.sidebar > ul > li > a.active > button {
    background-color: #fe9335;
    color: #fff;
}
.content-title {
    background-color: rgb(255, 255, 255);
    padding: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.content-body {
    background-color: rgb(255, 255, 255);
    padding: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    margin-top: 15px;
}

.container-blog {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
}
.container-blog > div {
    margin: 1% 1%;
}

main {
    margin-top: 10px;
    padding: 10px 10px 10px 20px;
    flex: 1;
}

/* SORRY, not MONEY */

.fr-wrapper div[style="z-index:9999;width:100%;position:relative"] {
    /* display: none; */
}
.second-toolbar #logo {
    display: none;
}

/* END */

.image {
    width: 70%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.uploadImage {
    width: 100%;
    display: flex;
    padding: 15px;
    height: 450px;
}
.uploadImage > label {
    display: flex;
    align-items: center;
}
.uploadImage > label > p {
    padding: 15px;
    color: #ff8e36;
    transition: all 0.5s;
    border: 1px solid #ff8e36;
    cursor: pointer;
}
.uploadImage > label > p:hover {
    background-color: #ff8e36;
    color: #fff;
}
.uploadImage > label > input {
    display: none;
}


ul.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: flex-end;
}

ul.pagination > li {width: 80px;height: 25px;margin: 0 20px;border-radius: 999px;background: #FF4081;cursor: pointer;box-shadow: 0 5px 5px -5px rgba(255, 64, 129, 0.15), 0 10px 10px -5px rgba(255, 64, 129, 0.15), 0 15px 15px -5px rgba(255, 64, 129, 0.15), 0 20px 20px -5px rgba(255, 64, 129, 0.15);transition: 0.25s ease;display: flex;align-items: center;justify-content: center;color: #fff;padding: 10px;}

ul.pagination > li.active {
    width: 120px;
}

ul.pagination > li > a {
    color: #fff;
}
